.HelpBox:after {
  content: '';
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 2px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  bottom: -10px;
  right: 50px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #fff #fff transparent;
}
.HelpBox {
  padding-top: 1em;
  padding-bottom: 1em;
  padding: 10px;
}

.SuggestionList {
  list-style: none;
}
.helpBoxContainer {
  margin-top: auto;
  position: relative;
}

.HelpBoxInverted:after {
  content: '';
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.2) -2px -2px 2px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: 0px;
  right: 50px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent #fff;
}
.HelpBoxInverted {
  padding-top: 1em;
  padding-bottom: 1em;
  padding: 10px;
}
