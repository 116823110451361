.cl-grey {
  color: var(--grey);
}

.result-bar {
  color: white;
  background-color: var(--blue);
}

.delete-border th {
  border: none;
}

.no-result-info {
  margin-top: 200px;
  color: #e0e0e0;
  text-align: center;
}
