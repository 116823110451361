:root {
  --light-grey-color: #eae9e5;
}

#filterColumn {
  min-width: 300px;
}

.result-field {
  /* background-color: var(--light-grey-color); */
}
.boostChip {
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.paperbox {
  padding: 10px;
}
.boostButton {
  word-wrap: break-word;
  word-break: break-word;
}

.ratingStars {
  font-size: 28px;
}

.visibleBox {
  position: sticky !important;
  top: 10px;
}
