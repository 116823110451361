.searchField {
  width: 100%;
  position: relative;
}
.react-autosuggest__suggestions-container--open {
  position: absolute;
  background: white;
  z-index: 10;
  width: 100%;
}
.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
}

.react-autosuggest__input--focused {
  border-bottom: 2px solid rgb(51, 183, 178) !important;
}
