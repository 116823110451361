/* @media all and (min-width: 480px) { */
/* .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  } */

.Login {
  max-width: 750px;
}
.registerPaper {
  padding: 1.5em;
}

.registerLogo {
  max-height: 100px;
  max-width: 500px;
}

.registerPage-textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-welcomeHeadline {
  font-size: 48px;
  padding-top: 24px;
  text-align: center;
  /* padding-bottom: 6px; */
  /* padding-bottom: 0.5em; */
}

.register-welcomeSubheadline {
  padding-bottom: 1em;
  /* padding-bottom: 6px; */
  /* padding-bottom: 0.5em; */
}

.registerPage-passwordRequirements-container {
  max-width: 400px;
  text-align: left;
  /* font-size: 14px; */
  padding-top: 2em;
  /* padding-bottom: 1em; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-welcomeDescription .list-passwordRequirements {
  width: 100%;
  text-align: left;
  /* padding-top: 1em; */
}

.login-button {
  margin-top: 2em;
}
.Login .errorContainer {
  margin: 0 auto;
  max-width: 320px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #df0101;
  color: #df0101;
}

h1 {
  font-weight: 700;
}

.list-passwordRequirements ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0em;
  /* text-indent: -1em; */
}

.registerPage-listItem {
  display: flex;
}

.registerPage-listItem .material-icons {
  font-size: 20px;
}

.list-icon-check {
  color: #28a745;
}

.list-icon-missing {
  color: #df0101;
}

.list-icon-missing-gray {
  color: #999;
}

.registerPage-passwordRepeated {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.registerPage-passwordRepeated-validationCheck {
  padding: 0px 0px 6px 6px;
  margin-right: -6px;
}
