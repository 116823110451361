.markListsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.markListsPage-listOverview-container {
  max-width: 800px;
  width: 100%;
}

.markListsPage-listContent-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  max-height: 550px;
  overflow: auto;
}

.marklist-listitem {
  width: 100%;
}

.markListsPage-listContent-button {
  margin-top: 20px;
  /* display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center; */
}

.marklist-noListsFound {
  font-size: 20px;
  font-weight: 400;
  color: #ccc;
}

.marklist-emptyList {
  font-size: 20px;
  font-weight: 400;
  color: #ccc;
  width: 100%;
  text-align: center;
}
.marklist-details {
  background: #f9f9f9;
  border-radius: 20px;
  /* margin: 1em; */
  padding: 1em;
}
.CircularProgressbar {
  width: 50px;
  height: 50px;
}

/* 

.marklist-listitem .marklist-deleteItem {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
} */
/* .marklist-listitem .marklist-deleteItem .material-icons {
  font-size: 32px;
  margin-right: -16px;
} */
