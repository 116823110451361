.registerTanPage {
}

.registerTanPage .headline {
  padding-top: 32px;
  text-align: center;
  /* background: #ff0; */
}

.registerTanPage-content {
  /* background: #ff0; */
  max-width: 800px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 12px;
  border: 1px solid #ddd;
  padding: 6px 12px 12px 12px;
}

.registerTanPage-description {
  margin: 24px 0px;
}

.registerTanPage-steps {
  /* margin: 24px 0px; */
  text-align: left;
  max-width: 600px;
}
.registerTanPage-step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.registerTanPage-step .stepNumber {
  font-size: 24px;
  color: #ccc;
  font-weight: 500;
  margin-right: 18px;
  width: 120px;
  text-align: left;
  /* background: red; */
}

.registerTanPage-step .stepDescription {
  margin-top: 4px;
  width: 100%;
}

.QRCodeImage,
.registerTanPage-loading-indicator-container {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.registerTanPage-loading-indicator-container {
  height: 180px;
  align-items: center;
}

.registerTanPage-QRImagePlaceholder {
  height: 150px;
  width: 150px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerTanPage-initialTan-container {
  display: flex;
  justify-content: center;
  padding: 12px 0px;
}

.registerTanPage-initialTan-form {
  width: 200px;
}

.registerTanPage-button-container {
  padding-top: 16px;
}

.successMessage-container {
  border: 1px solid green;
  color: green;
  background-color: rgb(0, 128, 0, 0.1);
  padding: 16px;
  margin-top: 18px;
}

.successMessage-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registerTanPage-successButton-container {
  margin-top: 16px;
}

.errorMessage-container {
  border: 1px solid red;
  color: red;
  background-color: rgb(128, 0, 0, 0.1);
  padding: 16px;
  margin-top: 18px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
