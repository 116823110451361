.willingnessToChangeNumber {
  font-size: 5em;
  color: var(--teal);
}
.mainBoxWrapper {
  text-align: left;
  background-color: white;
  border-top: 5px solid;
  border-top-color: var(--teal);
  color: var(--grey);
  font-size: 12px;
  cursor: pointer;
}

.mainBoxWrapper.marked {
  background-color: var(--background-secondary);
  border-color: var(--secondary);
}

.btn:focus {
  box-shadow: none;
}

.candidate-name {
  font-size: 1.5rem;
}

.button-more-info {
  margin-top: 10px !important;
}
.blurry {
  color: transparent !important;
  text-shadow: 0 0 15px rgb(63, 81, 181);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cl-red {
  color: var(--status-red);
}

.cl-green {
  color: var(--status-green);
}

.cl-blue {
  color: var(--blue);
}

.cl-dred {
  color: var(--teal);
}

.font-size-title {
  font-size: 1rem;
}

.headerInfo {
}

.expanderBar {
  cursor: pointer;
}

.expanderBar i {
  font-size: 48px;
  color: var(--blue);
}

/*
 * react-circular-progressbar styles
 *
 * All of the styles in this file are optional and configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   *
   * If you're not using "display: flex", you can remove this style.
   */
  width: 80px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted
  .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}
