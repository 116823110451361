.detailLabel {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0;
}
.detailField {
  word-break: break-word;
  margin-bottom: 1rem;
  display: inline-block;
  white-space: pre-line;
}

.detailField.dense {
  margin-bottom: 0;
}
