.SortLabel {
  border: none;
  background-color: rgb(0, 0, 0, 0);
}

.SortLabel {
  outline: none;
  cursor: pointer;
}

.SortLabel.active {
  font-weight: bold;
}
