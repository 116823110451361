@media all and (min-width: 480px) {
  .errorContainer {
    margin: 0 auto;
    max-width: 320px;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #df0101;
    color: #df0101;
  }
}
