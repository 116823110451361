.base {
  box-sizing: border-box;
}
.clickable {
  cursor: pointer;
}
.active {
  box-shadow: 0px 0px 0px 2px #6c6;
  border-radius: 5;
  background-color: #eee;
}
.reject {
  box-shadow: 0px 0px 0px 2px #c66;
  border-radius: 5;
  background-color: #eee;
}
.overlay {
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(200, 200, 200, 0.6);
  pointer-events: none;
}
.base:hover .overlay,
:global(.dropzone--isActive) .overlay {
  opacity: 1;
}
