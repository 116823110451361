#sendOrderBox-dialog-title {
  padding-bottom: 6px;
}

#sendOrderBox-dialog-subheadline {
  padding-bottom: 0px;
}
#sendOrderBox-dialog-buttonContainer {
  border-top: 1px solid #ccc;
  padding-top: 8px;
  margin-left: 0px;
  margin-right: 0px;
}

.listItem:not(:last-child) {
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
}

.listItem .headline {
  padding: 24px 0px 6px 0px;
}

.listItem .details {
  padding: 0px 18px 30px 12px;
  font-size: 12px;
}

.listItem .details:last-child {
  padding-bottom: 0px;
  font-size: 12px;
}

.listItem .details .label {
  padding-bottom: 6px;
  word-wrap: break-word;
}

.listItem .details .CircularProgressbar {
  width: 50px;
  height: 50px;
}

.listItem .details .CircularProgressbar text {
  font-size: 28px;
}
