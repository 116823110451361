.projectCompanyName {
  display: flex;
  text-align: center;
}
.projectArchive-companyName {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
}

.projectDetailLabel {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0;
}
