.paddingContainer.row {
  padding: 0px 12px 0px 12px;
}

.row.text-left label {
  padding: 3px 0px 3px 0px;
}

.checkFilterBox {
  text-align: left;
  text-transform: capitalize;
}
.checkFilterBox > fieldset > legend {
  text-transform: none;
}

.checkFilterLabel {
  background-color: #ffffff;
  margin: 0 !important;
  /* height: 2rem; */
}

.checkFilterLabel:hover {
  background-color: var(--blue);
}
.checkFilterLabel:hover > span {
  color: #fff;
}

.ExpansionPanelClass {
  padding: 0 !important;
}
.chkBox_ {
  height: auto !important;
}
