.companyDetailName {
  text-align: center;
}

.companyArchiv-frontIcon {
  size: 200px;
}

.companyDetailName {
  display: flex;
}

.companyArchive-companyName {
  width: 100%;
  font-size: 1.35rem;
  font-weight: 500;
}

.companyDetailLabel {
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0;
}

.companyArchive-companyLogo {
  width: 45px;
  float: right;
}

.companyArchive-companyStatus-container {
  font-weight: 500;
}

.companyArchive-companyStatus-container button {
  padding: 0px;
}

.companyArchive-companyStatus-text {
  padding: 0.5rem;
}

.companyArchive-directContactStatus-text {
  padding-bottom: 0.5rem;
  color: red
}

.companyArchive-companyStatus-text .partner {
  font-weight: 400;
  font-size: 12px;
}

.companyArchive-companyStatus-text.orange {
  color: orange;
}

.companyArchive-companyStatus-text.red {
  color: red;
}

.companyArchive-companyStatus-text.green {
  color: green;
  background-color: transparent;
}