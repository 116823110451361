.table-users-container,
.table-monitoring-container {
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.emptyPage {
  font-size: 20px;
  font-weight: 500;
  color: #ccc;
  /* width: 80%; */
  text-align: center;
  min-height: 50%;
  padding-top: 100px;
}

.table-users,
.table-monitoring {
  /* width: 1200px; */
  max-width: 95%;
}

.adminPage-usersTable__actionButtons {
  min-width: 150px;
}

.adminPage-usersTable__actionButtons > button {
  margin: -8px;
}

.successIcon-userCreation {
  display: block;
  text-align: center;
  font-size: 100px;
  color: #00a69f;
  /* TODO use var */
}

.createUser-success-dialog-title {
  text-align: center;
}

.createUser-dialog-title {
  text-align: center;
  font-size: 24px;
  padding: 12px;
}

.userDeleteConfirmation-contentText,
.userResetConfirmation-contentText {
  font-weight: 600;
  margin-top: 5px;
  /* padding-left: 10px; */
  /* text-align: center; */
}

.adminPage-userStatus.status-error {
  color: red;
}

.adminPage-userStatus.status-ok {
  color: #39a9dc;
}

.adminPage-userStatus.status-active {
  color: green;
}

.adminPage-table-actionsLabel {
  padding-left: 20px;
}
