.stars {
  display: inline-block;
}

.stars input {
  display: none;
}

.stars label {
  float: right;
  padding: 2px;
  font-size: 24px;
  color: #ccc9bf;
  transition: all 0.2s;
}

.stars label:before {
  content: '\2605';
  cursor: pointer;
}

.stars input:checked ~ label:before {
  color: var(--blue);
  transition: all 0.25s;
}

.stars label:hover {
  transform: scale(1.3);
}
