.jobPosting-editor {
  min-height: 500px;
}

.jobPosting-icon-size-60px-disabled {
  font-size: 60px;
  color: #a6a6a6;
}

.jobPosting-icon-size-60px-enabled {
  font-size: 60px;
  color: #962225;
}
