.searchField {
  max-width: 100%;
}

.maincontainer {
  margin-top: 20px;
}

.paperbox {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: auto;
}
.searchFieldColumn {
  margin-bottom: 2em;
}
