.candidate-details {
  z-index: 10;
}

.candidate-details__group-container {
  margin-bottom: 12px;
}

.candidate-details__name {
  display: flex;
  justify-content: center;
  width: 98%;
  font-size: 18px;
  font-weight: 600;
}

.candidate-details__status {
  margin: 4px 12px 0 12px;
  position: inline;
  width: 14px;
  height: 14px;
  top: 10px;
  left: 25px;
  border-radius: 7px;
}
.candidate-details__status-description {
  /* margin-top: -12px; */
  text-align: center;
}

.green {
  background-color: green;
}

.orange {
  color: orange;
}
.red {
  color: red;
}

.candidate-details .MuiIconButton-root {
  padding: 5px !important;
}

.candidate-details__db-search {
  margin: 0.5rem 0 1rem 0;
}
