.SearchResultCountBox {
  padding: 24px 0px 16px 0px;
  /* max-width: 800px; */
  text-align: center;
}

.ctm-blue-bg p {
  margin: 0px;
}
.ctm-green-bg p {
  margin: 0;
  padding: 8px;
}

.SearchResultCountBox-Header {
  font-size: 22px;
  color: #fff;

  margin-bottom: 0px;
}

.SearchResultCountBox-Count {
  font-size: 80px;
  font-weight: bold;
  color: white;
}

.cl-white .MuiCircularProgress-svg-137 {
  color: white;
}

.ctm-green-bg {
  color: #00a096;
}

.ctm-grey-bg {
  color: #ffffff;
}

.ctm-red-bg {
  color: #c50053;
}
