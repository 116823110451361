.select-text {
  margin-top: 50%;
  color: #e0e0e0;
  text-align: center;
}

.sideBarWrapper {
  text-align: left;
  font-size: 12px;
}
